import { STRAPI_KEY } from '../../helpers/settings/Settings'
import { useEffect, useState } from 'react'
import BlogLayout from "../../layouts/BlogLayout";
import PageBuilderComponent from "../../components/PageBuilderComponent";
import { useAnalytics } from '../../hooks/useAnalytics';
import { v4 as uuid } from 'uuid'

export default function BenefitsPage() {

  const [nData, setData] = useState(null)
  const [canSendEvent, setCanSendEvent] = useState(false)


  const { error, sendEvent } = useAnalytics({
    type: 'pageview',
    referrer: '/beneficios',
    session_token: localStorage.getItem('session') || uuid(),
    totem: process.env.REACT_APP_TOTEM_NAME,
    canSend: canSendEvent
  })

  useEffect(() => {
    if (!nData) {
      fetch(`${process.env.REACT_APP_API_URL}/totem-pages/1?populate=page_header_image,tabs,tabs.featured_image`, { headers: { Authorization: `Bearer ${STRAPI_KEY}` } })
        .then(response => response.json())
        .then(data =>  setData(data.data.attributes))
    }
  }, [nData])

  useEffect(() => {
    if (nData) {
      setCanSendEvent(true)
    }
  }, [nData])
  
  return (
    <BlogLayout pageId="w-beneficios" >
      {nData && <PageBuilderComponent eventTracker={sendEvent} pageHeaderImage={process.env.REACT_APP_UPLOAD_URL+nData.page_header_image.data.attributes.url} pageData={nData} /> }
    </BlogLayout >
  )

}