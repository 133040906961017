import { Link } from 'react-router-dom'
import { STRAPI_KEY } from '../../helpers/settings/Settings'
import { useEffect, useState, useLayoutEffect } from 'react'
import { v4 as uuid } from 'uuid'
import { useAnalytics } from '../../hooks/useAnalytics'

import './HomePage.css'

export default function HomePage() {

  const [data, setData] = useState(null)
  const [canSendEvent, setCanSendEvent] = useState(false)

  const startSession = () => {
    const session = uuid()
    localStorage.setItem('session', session)
    return session;
  }

  const { error } = useAnalytics({
    type: 'pageview',
    referrer: '/Home',
    session_token: startSession(),
    totem: process.env.REACT_APP_TOTEM_NAME,
    canSend: canSendEvent
  })

  useEffect(() => {   
    if (!data) {
      fetch(`${process.env.REACT_APP_API_URL}/totems/1?populate=%2A`, { headers: { Authorization: `Bearer ${STRAPI_KEY}` } })
        .then(response => response.json())
        .then(data => {
          document.body.classList.remove('handicap')
          setData(data.data.attributes)
        })
    }
  }, [])

  // use layout effect to set the canSendEvent to true
  useLayoutEffect(() => {
    if (data) {
      setCanSendEvent(true)
    }
  }, [data])

  return (
    <div id="w-descanso" className="wrapper">
      {!data && <div>Loading...</div>}
      {data && <>
        <img className="logo" src={`${process.env.REACT_APP_UPLOAD_URL}${data.app_logo.data.attributes.url}`} alt={data.app_logo.data.attributes.alternativeText} />
        <Link to={data.cta_button_href}>
          <button>
            <span>{data.cta_button_text}</span>
          </button>
        </Link>
        <video autoPlay={true} muted={true} loop={true} id="video">
          <source src={`${process.env.REACT_APP_UPLOAD_URL}${data.backdrop_video.data.attributes.url}`} type={data.backdrop_video.data.attributes.mime} />
        </video>
      </>}
    </div>
  )
}
