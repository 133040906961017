import { useEffect, useState } from "react";
import PageContentComponent from "./PageContentComponent";
import { useSearchParams } from "react-router-dom";

export default function PageBuilderComponent({ pageHeaderImage = false, pageData = false, layout = "default", stepsData = false, menuClass = "m-beneficios", contentId, eventTracker }) {

    const [active, setActive] = useState(false)
    const [queryParams] = useSearchParams()

    useEffect(() => {
        if (pageData) {
            let tab = queryParams.get('tab')
            if (tab) {
                let tabContent = pageData.tabs.filter(item => item.id === tab && item)
                setActive(tabContent.length > 0 ? tabContent[0] : pageData.tabs[0])
            }
        }
    }, [queryParams, pageData])

    useEffect(() => {
        if (!active)  setActive(pageData.tabs[0])
    }, [active, pageData])


    const handleClick = (item) => {
        const event = {
            type: 'pageview',
            referrer: '/beneficios/'+item.cta_button_label,
            session_token: localStorage.getItem('session'),
            totem: process.env.REACT_APP_TOTEM_NAME,
            canSend: true
        }
        eventTracker(event)
        setActive(item)
    }

    /** sidebar-tabs-2-columns */
    return (
        <div className="cont-interior">

            {pageHeaderImage &&
                <div className="banner">
                    <img src={pageHeaderImage} alt="Beneficios" />
                </div>
            }

            {active &&
                <nav className={`menu-lat ${menuClass}`}>
                    {pageData.tabs.map(item => (<button key={item.id} className={`${(active && active.id === item.id) ? "active" : ""}`} onClick={() => handleClick(item)} dangerouslySetInnerHTML={{ __html: item.cta_button_label }} />))}
                </nav>
            }

            {active && layout === 'default' && <PageContentComponent pageContent={active} contentId={contentId} />}
            
        </div>
    )
}
