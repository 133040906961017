import { STRAPI_KEY } from '../../helpers/settings/Settings'
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAnalytics } from '../../hooks/useAnalytics';

import backIcon from "../../assets/img/ico-back.png"
import bannerImg from '../../assets/img/bnr-uso.jpg'
import BlogLayout from "../../layouts/BlogLayout"
import CarType from "../../components/CarType"
import Grid from "./Grid";
import SidebarNavItem from "../../layouts/SidebarNavItem"

export default function ModelsPage() {

    const [currentModels, setCurrentModels] = useState(null)
    const [isModelView, setIsModelView] = useState(false)
    const [pageData, setPageData] = useState(null)
    const [currentTab, setCurrentTab] = useState(null)

    const [queryParams] = useSearchParams()

    const { error, sendEvent } = useAnalytics({
        type: 'pageview',
        referrer: '/modelos',
        session_token: localStorage.getItem('session'),
        totem: process.env.REACT_APP_TOTEM_NAME,
        canSend: true
      })

    const layoutInfo = (currentTab) => {
        if (!currentTab) return { id: "cont-tipo", class: "cont-tipos" }

        switch (currentTab.layout) {
            case "Tipo vehículo":
                return { id: "cont-tipo", class: "cont-tipos" }

            case "Rango de precio":
                return { id: "cont-rango-precios", class: "cont-precios" }

            case "Uso":
                return { id: "cont-uso", class: "cont-usos" }

            case "Asientos":
                return { id: "cont-pasajeros", class: "cont-pasajeros" }

            case "Transmisión":
                return { id: "cont-transmision", class: "cont-transmision" }

            default:
                return { id: "cont-tipo", class: "cont-tipos" }
        }
    }

    useEffect(() => {
        if (!pageData) {
            fetch(`${process.env.REACT_APP_API_URL}/tipos-de-vehiculos?populate=tipos.background_image,tipos.featured_image,tipos,tipos.modelos, tipos.modelos.featured_image`, { headers: { Authorization: `Bearer ${STRAPI_KEY}` } })
                .then(response => response.json())
                .then(data => setPageData(data.data))
        }
    }, [pageData])

    useEffect(() => {
        if (pageData) {
            let tab = queryParams.get('tab')
            if (tab) {
                let tabContent = pageData.filter(item => item.id.toString() === tab.toString() && item)
                setCurrentTab(tabContent.length > 0 ? tabContent[0].attributes : pageData[0].attributes)
            } else {
                setCurrentTab(pageData[0].attributes)	
            }
        }
    }, [queryParams, pageData])

    const handleItemClick = (item) => {
        setIsModelView(false)
        setCurrentTab(item)
    }

    const handleTypeClick = (type) => setIsModelView(true)

    return (
        <BlogLayout pageId="w-modelos">
            <div className="cont-interior">
                <div className="banner">
                    <img src={bannerImg} alt="" />
                </div>

                <nav className="menu-lat">
                    {currentTab &&
                        pageData.map(item =>
                            <SidebarNavItem
                                eventTracker={sendEvent}
                                itemId={item.id}
                                key={item.attributes.button_label}
                                item={item.attributes}
                                current={currentTab}
                                updateCurrentTab={handleItemClick}
                            />
                        )}
                </nav>

                <div className="contenido" id={layoutInfo(currentTab).id.toString()}>

                    {isModelView && currentModels &&
                        <div className="head-categoria">
                            <button onClick={() => setIsModelView(false)}>
                                <img src={backIcon} alt="" />
                            </button>
                            <span>{currentModels.title}</span>
                        </div>
                    }

                    {!isModelView &&
                        <div className={layoutInfo(currentTab).class.toString()}>
                            {currentTab && currentTab.tipos && currentTab.tipos.map((tipo, index) => <CarType eventTracker={sendEvent} delay={index} key={"tipo-" + tipo.nombre} layout={layoutInfo(currentTab)} type={tipo} clickType={handleTypeClick} setCurrentModels={setCurrentModels} />)}
                        </div>
                    }

                    {isModelView &&
                        currentModels && currentModels.modelos &&
                        <Grid modelos={currentModels.modelos} />
                    }
                </div>

            </div>

        </BlogLayout>
    )
}
