import { Fragment } from "react";
import Model from "../../components/CarType/Model";

export default function Grid({ modelos }) {

    const getCols = () => {
        if (modelos.length > 2 && modelos.length <= 9 )  return "cont-3";
        if (modelos.length > 9 )  return "cont-4";
        return "cont-1";
    }

    const colCount = () => {
        if (modelos.length > 2) {
            return modelos.length % 3 === 0 ? 3 : 4
        } else {
            return 1
        }
    }

    const drawHr = (i) => {
        // si hay solo 2 modelos, hay que dibujar un hr
        if (modelos.length === 2) {
            return i === 0 && <hr />
        }

        // si hay mas de 2 modelos y menos de 9, hay que dibujar un hr cada 3
        if (modelos.length > 3 && modelos.length <= 9) {
            if ( (i / 3) < (colCount() - 1) ) return i % 3 === 2 && <hr />
        }

        // si hay mas de 9 modelos y no es la ultima fila, hay que dibujar un hr cada 4
        if (modelos.length > 9 ) {
            if ( (i / 4) < colCount() - 1 ) return i % 4 === 3 && <hr />
        }
    }

    return (
        modelos &&
        <div className={getCols()}>
            {modelos.map((modelo, i) =>
                <Fragment key={"modelo-"+modelo.id}>
                    <Model
                        delay={i}
                        ficha={modelo.id_ficha}
                        name={modelo.nombre}
                        image={`${process.env.REACT_APP_UPLOAD_URL}${modelo.featured_image.data.attributes.url}`}
                    />
                    {drawHr(i) && <hr />}
                </Fragment>
            )}
        </div>
    )
}
