import backIcon from '../../assets/img/ico-volver-n.png'
import homeIcon from '../../assets/img/ico-inicio-n.png'
import headerLogos from '../../assets/img/logos-header.png'
import { useNavigate } from 'react-router-dom'
import NavButton from './NavButton'

export default function HeaderComponent() {
    
    const navigate = useNavigate()


    return (
        <div id="header">
            <img src={headerLogos} alt="" className="logos-head" />
            <div className="cont-menu">
                <button onClick={ () => navigate(-1) }>
                    <img src={backIcon} alt="" />
                    <span>VOLVER</span>
                </button>
                <button onClick={ () => navigate('/') }>
                    <img src={homeIcon} alt="" />
                    <span>INICIO</span>
                </button>
                <NavButton />
            </div>
        </div>
    )
}
