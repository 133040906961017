import HeaderComponent from "./HeaderComponent";
import { AnimatePresence, motion } from 'framer-motion'
import FooterComponent from "./FooterComponent";
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from "react-router-dom";

export default function BlogLayout({ children, pageId }) {

    let navigate = useNavigate()
    const onIdle = () => navigate('/')
    
    useIdleTimer({ onIdle, timeout: 60000, debounce: 500 })

    return (
        <AnimatePresence exitBeforeEnter>
            <motion.div
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
            >
                <div id={pageId} className="wrapper" style={{ backgroundColor: "#F5F5F5" }}>
                    <HeaderComponent />
                    {children}
                </div>
                <FooterComponent />

            </motion.div>
        </AnimatePresence>

    )
}
