
export default function Footer({ banner, qr, text }) {
    return (
        <div className="cont-qr">
            <div dangerouslySetInnerHTML={{ __html: text }} className="descripcion" />
            <img className="escanea" src={banner} alt="" />
            <img className="cod-qr" src={qr} alt="" />
        </div>
    )
}
