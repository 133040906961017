import React from 'react'

export default function Badge({ image, text }) {
    return (
        <div className="item-sello">
            <img src={image} alt="" />
            <span dangerouslySetInnerHTML={{__html: text}} />
        </div>
    )
}
