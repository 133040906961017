import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { STRAPI_KEY } from '../../helpers/settings/Settings'
import BlogLayout from '../../layouts/BlogLayout'
import Accordion from './Accordion'
import { useAnalytics } from '../../hooks/useAnalytics'

export default function ModelPage() {

    const [data, setData] = useState(null)
    const [exteriorData, setExteriorData ] = useState(null)
    let { id } = useParams()

    const { error } = useAnalytics({
        type: 'pageview',
        referrer: '/modelos-'+id,
        session_token: localStorage.getItem('session'),
        totem: process.env.REACT_APP_TOTEM_NAME,
        canSend: true
      })

    useEffect(() => {
        if ( data === null ) {
            fetch(`${process.env.REACT_APP_API_URL}/modelos/${id}?populate=personaliza,personaliza.imagen_destacada,personaliza.accesorios,personaliza.qr_catalogo,imagen_360,galeria_imagenes,variantes.imagen,variantes.caracteristicas_motor,variantes.caracteristicas_dimensiones_capacidades,variantes.caracteristicas_seguridad,variantes.caracteristicas_colores,variantes.caracteristicas_exterior,variantes.caracteristicas_interior,variantes,logo,header_image,Color,Color.imagen,caracteristicas_modelo,caracteristicas_modelo.imagen,interior,interior.featured_image`, { headers: { Authorization: `Bearer ${STRAPI_KEY}` } })
                .then(res => res.json())
                .then(data => setData(data))
                .catch(err => window.location.href = '/')
        }
    }, [data, id])

    useEffect(() => {
        if (data ) {
            setExteriorData(data.data.attributes.caracteristicas)
        }
    }, [data, exteriorData])

    return (
        <BlogLayout pageId="w-modelos">
            <div className="cont-interior">
                {data &&
                    <>
                        <div className="banner">
                          { data.data.attributes.header_image && <img src={`${process.env.REACT_APP_UPLOAD_URL}${data.data.attributes.header_image.data.attributes.url}`} alt="" /> }
                        </div>

                        <div className="contenido" id='cont-ficha'>
                            <Accordion tabData={data} />
                        </div>
                    </>
                }
            </div>
        </BlogLayout>
    )
}
