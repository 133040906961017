import { useState } from 'react'
import Panel from './Panel'

export default function Accordion({ tabData }) {

    const [active, setActive] = useState(1)

    const mainTabs = [
        { id: 1, title: 'CARACTERÍSTICAS', layout: "features" },
        { id: 2, title: 'IMÁGENES', layout: "gallery" },
        { id: 3, title: 'COMPARA VERSIONES', layout: "comparador" },
        { id: 4, title: 'ACCESORIOS', layout: "accesories" },
    ]

    return (
        <>
            {mainTabs.map((tab) => {
                return (
                    <div key={"tab-" + tab.id}>
                        <button className={`accordion-n1 ${tab.id === active ? 'active' : ''}`} onClick={() => setActive(tab.id)}>{tab.title}</button>
                        <div className={`panel ${tab.id === active ? 'active' : ''}`}>
                            <Panel layout={tab.layout} data={tabData} />
                        </div>
                    </div>
                )
            })}
        </>
    )
}
