import { useEffect,useState } from 'react'
import Exterior from './Exterior'
import Interior from './Interior'

export default function Features({data}) {
    
    const [currentTab, setCurrentTab] = useState(1)
    const [tabContent, setTabContent] = useState(null)

    const tabs = [
        { id: 1, title: 'Exterior', subtitle: 'Una vida espectacular está en los detalles' },
        { id: 2, title: 'Interior', subtitle: 'Tecnología para volver a la infancia' },
    ]
 
    useEffect(() => {
        if (!tabContent) return setTabContent(tabs[0])
    }, [tabs, tabContent])

    const handleTabClick = (tab) => {
        setTabContent(tab)
        setCurrentTab(tab.id)
    }

    return (
        <>
            <div className="tab">
                {tabs.map((tab) => <button key={tab.id + "-featured-tab"} className={`tablinks ${currentTab === tab.id ? 'active' : ''}`} onClick={() => handleTabClick(tab)}>{tab.title}</button>)}
            </div>

            <div id="tab1" className="tabcontent" style={{ display: "block" }}>
                { tabContent && <span className="tit-caract">{tabContent.subtitle}</span> }

                {tabContent && tabContent.id === 1 &&
                    <Exterior data={data}/>
                }

                {tabContent && tabContent.id === 2 &&
                    <Interior data={data} />
                }
            </div>
        </>
    )
}
