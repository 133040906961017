import { useEffect, useState } from 'react'

export default function Exterior({ data }) {

    const [color, setColor] = useState(null)

    useEffect(() => {
        if (color === null) {
            setColor(data.data.attributes.Color[0])
            console.log(data.data.attributes.Color[0])
        }
    }, [color, data])

    return (
        <>
            {color &&
                <div className="cont-img-color">
                    <img id="img-vehiculo" src={`${process.env.REACT_APP_UPLOAD_URL}${color.imagen.data.attributes.url}`} alt="" />
                    <div className="cont-colores">
                        {data.data.attributes.Color.map((color) => <button key={color.id} id={color.color} className="active" style={{ backgroundColor: color.color_data }} onClick={() => setColor(color)} ><div className="coloruno"><span className="colordos">{color.color}</span></div></button>)}
                    </div>
                </div>
            }
            {data && data.data.attributes &&
                <div className="cont-caractertisticas">
                    {data.data.attributes.caracteristicas_modelo.map((car) => (
                        <img src={`${process.env.REACT_APP_UPLOAD_URL}${car.imagen.data.attributes.url}`} className="item-caract" alt='' key={car.id + "-feat-car"} />
                    ))}
                </div>
            }
        </>
    )
}
