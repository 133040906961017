import Accesories from "./Accesories"
import Features from "./Features"
import Gallery from "./Gallery"
import Comparador from "./Comparador"

export default function Panel({ layout, data }) {
    switch (layout) {
        case "features":
            return <Features data={data} />
        case "gallery":
            return <Gallery data={data} />
        case "accesories":
            return <Accesories data={data} />
        case "comparador":
            return <Comparador data={data} />
        default:
            return <div>Default</div>
    }
}
