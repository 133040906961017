import SectionLogo from '../../assets/img/logos-menu.png'
import LogoSalfa from '../../assets/img/logo-salfa.png'
import LogoModelos from '../../assets/img/ico-modelos.svg'
import Beneficios from '../../assets/img/ico-beneficios.svg'
import Otros from '../../assets/img/ico-otros.svg'
import MenuCircleButton from '../../components/MenuCircleButton/MenuCircleButton'
import { AnimatePresence, motion } from 'framer-motion'
import { useAnalytics } from '../../hooks/useAnalytics'
export default function MenuPage() {
    
    document.body.classList.remove('handicap')

    const { error } = useAnalytics({
        type: 'pageview',
        referrer: '/menu',
        session_token: localStorage.getItem('session'),
        totem: process.env.REACT_APP_TOTEM_NAME,
        canSend: true
      })

    const data  = [
        {
            id: 1,
            title: '',
            link: '/salfa',
            image: LogoSalfa,
        },
        {
            id: 2,
            text: 'Modelos',
            link: '/modelos',
            image: LogoModelos,
        },
        {
            id: 3,
            text: 'Beneficios',
            link: '/beneficios',
            image: Beneficios,
        },
        {
            id: 4,
            text: '<span>Otros<br />productos</span>',
            link: '/otros',
            image: Otros,
        }
    ]

    return (
        <AnimatePresence exitBeforeEnter>
            <motion.div
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
            >
                <div id="w-menu" className="wrapper">
                    <img className="logo" src={SectionLogo} alt="" />
                    <div className="cont-btns">
                        { data.map( item => <MenuCircleButton key={item.id+"-menu"} link={item.link ?? false} image={item.image ?? false} text={item.text ?? false} /> ) }
                    </div>
                </div>
            </motion.div>
        </AnimatePresence>
    )
}
