import { Link } from "react-router-dom";

export default function MenuCircleButton({ text, image, link }) {

    return (
        <Link to={link}>
            <button>
                {image && <img src={image} alt="" />}
                {text && <span dangerouslySetInnerHTML={{ __html: text }}></span>}
            </button>
        </Link>
    )
}
