import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

export default function Model({ name, image, ficha, delay = 0 }) {

    let navigate = useNavigate()

    const handleClick = () => navigate("/modelo/" + ficha)

    return (
        <motion.button
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
                duration: 0.5,
                delay: delay * 0.1,	
                ease: 0
            }}
            onClick={handleClick} >
            {image && <img src={image} alt="" />}
            < span > {name ?? ""}</ span>
        </motion.button>
    )
}
