import { useRef } from 'react'
import Slider from "react-slick";
export default function Gallery({ data }) {

    const galeria = data.data.attributes.galeria_imagenes
    const settings = {
        centerMode: true,
        infinite: true,
        slidesToShow: 1,
        speed: 500,
        slidesToScroll: 1,
        centerPadding: '20px',
        arrows: true,
    }

    return (
        <>
            <div style={{ width: " 100%", height: "600px", margin: "0 auto" }}>
                {galeria.data && galeria.data.length > 0 &&
                    <Slider {...settings}>
                        {galeria.data.map((item, index) => (
                            <div key={'slide-' + index}>
                                <img src={process.env.REACT_APP_UPLOAD_URL + item.attributes.url} alt="" />
                            </div>
                        ))}
                    </Slider>
                }
            </div>
        </>
    )
}
