
import { useNavigate } from 'react-router-dom'

export default function SidebarNavItem({ active = false, item, updateCurrentTab, current, itemId, eventTracker }) {

    const navigate = useNavigate()

    const handleClick = () => {
        console.log(itemId)

        updateCurrentTab(item)
        
        const event = {
            type: 'pageview',
            referrer: '/modelos/'+item.button_label,
            session_token: localStorage.getItem('session'),
            totem: process.env.REACT_APP_TOTEM_NAME,
            canSend: true
        }

        eventTracker(event)

        navigate({ search: `?tab=${itemId}`})
    }

    return (
        <button onClick={handleClick} className={ current.button_label === item.button_label ? "active" : "" } >
            <span>{item.button_label ?? ""}</span>
            <span>{item.button_label_info ?? ""}</span>
        </button>
    )
}
