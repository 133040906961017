import { STRAPI_KEY } from '../../helpers/settings/Settings'
import { useEffect, useState } from 'react'
import BlogLayout from "../../layouts/BlogLayout";
import PageBuilderComponent from "../../components/PageBuilderComponent";
import { useAnalytics } from '../../hooks/useAnalytics';

export default function BenefitsPage() {

  const [nData, setData] = useState(null)

  const { error, sendEvent } = useAnalytics({
    type: 'pageview',
    referrer: '/extras',
    session_token: localStorage.getItem('session'),
    totem: process.env.REACT_APP_TOTEM_NAME,
    canSend: true
  })

  useEffect(() => {
    if (!nData) {
      fetch(`${process.env.REACT_APP_API_URL}/totem-pages/2?populate=page_header_image,tabs,tabs.featured_image`, { headers: { Authorization: `Bearer ${STRAPI_KEY}` } })
        .then(response => response.json())
        .then(data => setData(data.data.attributes))
    }
  }, [nData])

  return (
    <BlogLayout pageId="w-beneficios" >
      {nData && <PageBuilderComponent eventTracker={sendEvent} pageHeaderImage={process.env.REACT_APP_UPLOAD_URL+nData.page_header_image.data.attributes.url} pageData={nData} /> }
    </BlogLayout >
  )

}