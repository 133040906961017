import menuIcon from '../../assets/img/ico-menu-g.svg'
import backIcon from '../../assets/img/ico-volver-g.svg'
import homeIcon from '../../assets/img/ico-inicio-g.svg'
import icoMinusv from '../../assets/img/ico-minusv.svg'
import icoClose from '../../assets/img/ico-close.svg'
import { useNavigate } from 'react-router-dom'
import SidebarNav from '../SidebarNav'

export default function FooterComponent() {

    const navigate = useNavigate()

    const handleHandicapClick = () => {
        document.body.classList.toggle('handicap')
        document.body.classList.remove('menu-active')
    }

    return (
        <>
            <SidebarNav />
            <div id="wrap-minusv">
                <button id="act-minusv" onClick={handleHandicapClick}>
                    <img className="ico-m" src={icoMinusv} alt="" />
                    <img className="ico-c" src={icoClose} alt="" />
                </button>
                <div className="cont-btns">
                    <button onClick={() => navigate(-1)}>
                        <img src={backIcon} alt="" />
                        <span>VOLVER</span>
                    </button>
                    <button onClick={() => navigate("/")}>
                        <img src={homeIcon} alt="" />
                        <span>INICIO</span>
                    </button>
                    <button onClick={() =>  document.body.classList.toggle('menu-active') }>
                        <img src={menuIcon} alt="" />
                        <span>MENÚ</span>
                    </button>
                </div>
            </div>
        </>
    )
}
