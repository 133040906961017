import { AnimatePresence, motion } from "framer-motion";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
export default function PageContentComponent({
  pageContent = false,
  contentId,
}) {


  return (
    <div className="contenido" id={contentId}>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={pageContent.id}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <h1>{pageContent.title}</h1>
          <img
            src={
              process.env.REACT_APP_UPLOAD_URL +
              pageContent.featured_image.data.attributes.url
            }
            alt={pageContent.title}
          />
          <div className="foo">
            <ReactMarkdown            
            remarkPlugins={[remarkGfm]}>{pageContent.body}</ReactMarkdown>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
