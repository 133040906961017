
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { motion } from 'framer-motion';
import React from 'react';
import ReactDOM from 'react-dom/client';
import HomePage from './pages/HomePage/HomePage';
import MenuPage from './pages/MenuPage/MenuPage';
import reportWebVitals from './reportWebVitals';
import SponsorPage from './pages/SponsorPage';
import BenefitsPage from './pages/BenefitsPage/BenefitsPage';
import ExtrasPage from './pages/ExtrasPage';
import ModelsPage from './pages/ModelsPage';
import ModelPage from './pages/ModelPage';

import './assets/css/normalize.css';
import './assets/css/main.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <motion.div id="main-app-wrapper"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.8,
        delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01]
      }}>
      <div className="handicap-space"></div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/menu" element={<MenuPage />} />
          <Route path="/salfa" element={<SponsorPage />} />
          <Route path="/beneficios" element={<BenefitsPage />} />
          <Route path="/otros" element={<ExtrasPage />} />
          <Route path="/modelos" element={<ModelsPage />} />
          <Route path="/modelo/:id" element={<ModelPage />} />
        </Routes>
      </BrowserRouter>
    </motion.div>
  </React.StrictMode>
);
reportWebVitals();
