import { useState, useEffect, useRef } from 'react'
import addIcon from '../../assets/img/ico-add.svg'

export default function Comparador({ data }) {

    const [selectedVariants, setSelectedVariants] = useState(null)
    const [variants, setVariants] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)

    const [currentTab, setCurrentTab] = useState("INTERIOR")

    const modalRef = useRef()
    const logo = process.env.REACT_APP_UPLOAD_URL + data.data.attributes.logo.data.attributes.url

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
    }, [])

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setModalOpen(false)
        }
    }

    useEffect(() => {
        if (variants === null) {
            setVariants(data.data.attributes.variantes.data)
        }
    }, [data, variants])

    useEffect(() => {
        if (selectedVariants === null && variants !== null) {
            setSelectedVariants([variants[0]])
            console.log(variants[0])
        }
    }, [selectedVariants, variants])


    const getVariantStauts = (variant, key) => {
        if (variant) {
            let features = variant.attributes.caracteristicas_interior
            let status = features.filter(feature => {
                if (feature.nombre === key) return feature
                return []
            })
            return status.length > 0 && status[0].estado ? status[0].estado : "No Aplica"
        } 
        
    }

    const addVariant = (variant) => {
        if (selectedVariants.length < 3 && !selectedVariants.includes(variant)) {
            setSelectedVariants([...selectedVariants, variant])
        } else {
            setSelectedVariants(selectedVariants.filter(item => item !== variant))
        }
    }

    return (
        <div id="specsGroups">
            {selectedVariants &&
                <div className="data">
                    <table className="cabecera">
                        <thead>
                            <tr>
                                <th style={{ width: "40%" }}>
                                    <img src={logo} alt="" />
                                </th>
                                {selectedVariants.map((variant) => {
                                    return (
                                        <th key={variant.id} style={{ width: "20%" }}>
                                            <img src={process.env.REACT_APP_UPLOAD_URL + variant.attributes.imagen.data.attributes.url} alt="" />
                                            <div className="tit-modelo" onClick={() => setModalOpen(true)}>
                                                <span className="version">{variant.attributes.nombre}</span>
                                                <span className="precio">DESDE $ {variant.attributes.precio_desde.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</span>
                                                <button><img src={addIcon} alt="" /></button>
                                            </div>
                                        </th>
                                    )
                                })}

                                {selectedVariants.length === 2 &&
                                    <th style={{ width: "20%" }}>
                                        <div className="tit-modelo" onClick={() => setModalOpen(true)}>
                                            <span className="version"></span>
                                            <span className="precio">Agregar Versión</span>
                                            <button><img src={addIcon} alt="" /></button>
                                        </div>
                                    </th>
                                }

                                {selectedVariants.length === 1 &&
                                    <>
                                        <th style={{ width: "20%" }}>
                                            <div className="tit-modelo" onClick={() => setModalOpen(true)}>
                                                <span className="version"></span>
                                                <span className="precio">Agregar Versión</span>
                                                <button><img src={addIcon} alt="" /></button>
                                            </div>
                                        </th>
                                        <th style={{ width: "20%" }}>
                                            <div className="tit-modelo" onClick={() => setModalOpen(true)}>
                                                <span className="version"></span>
                                                <span className="precio">Agregar Versión</span>
                                                <button><img src={addIcon} alt="" /></button>
                                            </div>

                                        </th>
                                    </>
                                }
                            </tr>
                        </thead>
                    </table>

                    <div className="accordion-item">
                        <button className="accordion stat-expand-icon" data-dtm="compare versions" onClick={() => setCurrentTab("INTERIOR")}>INTERIOR<i className="plus-minus-toggle"></i></button>
                        <div className={`table-container ${currentTab === "INTERIOR" ? 'active' : ''}`} >
                            <table className="table table-striped">
                                <tbody>
                                    {selectedVariants[0].attributes.caracteristicas_interior.map((variant, index) => {
                                        return (
                                            <tr key={'comparador-' + variant.id}>
                                                <td style={{ width: "40%" }}>{variant.nombre}</td>
                                                <td style={{ width: "20%" }}>{variant.estado === "Otro" ? variant.otro : variant.estado}</td>
                                                <td style={{ width: "20%" }}>{getVariantStauts(selectedVariants[1], variant.nombre)}</td>
                                                <td style={{ width: "20%" }}>{getVariantStauts(selectedVariants[2], variant.nombre)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div className="accordion-item">
                        <button className="accordion stat-expand-icon" data-dtm="compare versions" onClick={() => setCurrentTab("EXTERIOR")}>EXTERIOR<i className="plus-minus-toggle"></i></button>
                        <div className={`table-container ${currentTab === "EXTERIOR" ? 'active' : ''}`} >
                            <table className="table table-striped">
                                <tbody>
                                    {selectedVariants[0].attributes.caracteristicas_exterior && selectedVariants[0].attributes.caracteristicas_exterior.map((variant, index) => {
                                        return (
                                            <tr key={'comparador-' + variant.id}>
                                                <td style={{ width: "40%" }}>{variant.nombre}</td>
                                                <td style={{ width: "20%" }}>{variant.estado === "Otro" ? variant.otro : variant.estado}</td>
                                                <td style={{ width: "20%" }}>{getVariantStauts(selectedVariants[1], variant.nombre)}</td>
                                                <td style={{ width: "20%" }}>{getVariantStauts(selectedVariants[2], variant.nombre)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>



                    <div className="accordion-item">
                        <button className="accordion stat-expand-icon" data-dtm="compare versions" onClick={() => setCurrentTab("MOTOR")}>MOTOR<i className="plus-minus-toggle"></i></button>
                        <div className={`table-container ${currentTab === "MOTOR" ? 'active' : ''}`} >
                        <table className="table table-striped">
                                <tbody>
                                    {selectedVariants[0].attributes.caracteristicas_motor && selectedVariants[0].attributes.caracteristicas_motor.map((variant, index) => {
                                        return (
                                            <tr key={'comparador-' + variant.id}>
                                                 <td style={{ width: "40%" }}>{variant.nombre}</td>
                                                <td style={{ width: "20%" }}>{variant.estado === "Otro" ? variant.otro : variant.estado}</td>
                                                <td style={{ width: "20%" }}>{getVariantStauts(selectedVariants[1], variant.nombre)}</td>
                                                <td style={{ width: "20%" }}>{getVariantStauts(selectedVariants[2], variant.nombre)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <button className="accordion stat-expand-icon" data-dtm="compare versions" onClick={() => setCurrentTab("DIMENSIONES Y CAPACIDADES")}>DIMENSIONES Y CAPACIDADES<i className="plus-minus-toggle"></i></button>
                        <div className={`table-container ${currentTab === "DIMENSIONES Y CAPACIDADES" ? 'active' : ''}`} >
                        <table className="table table-striped">
                                <tbody>
                                    {selectedVariants[0].attributes.caracteristicas_dimensiones_capacidades && selectedVariants[0].attributes.caracteristicas_dimensiones_capacidades.map((variant, index) => {
                                        return (
                                            <tr key={'comparador-' + variant.id}>
                                                 <td style={{ width: "40%" }}>{variant.nombre}</td>
                                                <td style={{ width: "20%" }}>{variant.estado === "Otro" ? variant.otro : variant.estado}</td>
                                                <td style={{ width: "20%" }}>{getVariantStauts(selectedVariants[1], variant.nombre)}</td>
                                                <td style={{ width: "20%" }}>{getVariantStauts(selectedVariants[2], variant.nombre)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <button className="accordion stat-expand-icon" data-dtm="compare versions" onClick={() => setCurrentTab("SEGURIDAD")}>SEGURIDAD<i className="plus-minus-toggle"></i></button>
                        <div className={`table-container ${currentTab === "SEGURIDAD" ? 'active' : ''}`} >
                        <table className="table table-striped">
                                <tbody>
                                    {selectedVariants[0].attributes.caracteristicas_seguridad && selectedVariants[0].attributes.caracteristicas_seguridad.map((variant, index) => {
                                        return (
                                            <tr key={'comparador-' + variant.id}>
                                                 <td style={{ width: "40%" }}>{variant.nombre}</td>
                                                <td style={{ width: "20%" }}>{variant.estado === "Otro" ? variant.otro : variant.estado}</td>
                                                <td style={{ width: "20%" }}>{getVariantStauts(selectedVariants[1], variant.nombre)}</td>
                                                <td style={{ width: "20%" }}>{getVariantStauts(selectedVariants[2], variant.nombre)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }

            {modalOpen &&
                <div className="modal-versiones" style={{ display: "block" }}>
                    <div className="cont-modal" ref={modalRef}>
                        <h1>Seleccione versiones</h1>

                        <table>
                            <thead>
                                <tr><th>Seleccione hasta 3 versiones a comparar</th>
                                </tr></thead>
                            <tbody>
                                {variants.map((variant, index) => {
                                    return (
                                        <tr key={'modal-' + variant.id}>
                                            {index === 0 && <td><label><input checked disabled type="checkbox" id="cbox1" readOnly />{variant.attributes.nombre}</label></td>}
                                            {index !== 0 && !selectedVariants.includes(variant) && selectedVariants.length < 3 && <td><label><input readOnly={true} type="checkbox" id="cbox1" value="" onClick={() => addVariant(variant)} />{variant.attributes.nombre}</label></td>}
                                            {index !== 0 && !selectedVariants.includes(variant) && selectedVariants.length >= 3 && <td><label><input readOnly={true} disabled type="checkbox" id="cbox1" value="" />{variant.attributes.nombre}</label></td>}

                                            {index !== 0 && selectedVariants.includes(variant) && <td><label><input checked readOnly={true} type="checkbox" id="cbox1" value="" onClick={() => addVariant(variant)} />{variant.attributes.nombre}</label></td>}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div className="cont-btns">
                            <button className="btn-aceptar" onClick={() => setModalOpen(false)}>
                                <span>Aceptar</span>
                            </button>
                            <button className="btn-cancelar" onClick={() => setModalOpen(false)}>
                                <span>Cancelar</span>
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
