import { useEffect, useState } from "react";

export function useAnalytics( event ) {
    
    const [data, setData] = useState(false)
    const [error, setError] = useState(null)
    const [previousEvent, setPreviousEvent] = useState(null)

    useEffect(() => {
        
        if (!data && event.canSend) {
            console.log('Sending event', event)
            fetch(`${process.env.REACT_APP_ANALYTICS}/events`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(event)
            })
            .then(response => response.json())
            .then(data => {
                setData(data)
                setPreviousEvent(event.canSend)
            }
            )
            .catch(error => {
                console.log("No se pudo cargar la data...")
                setData(error)
                setPreviousEvent(false)
                setError(error)
            }
            )
        }
        
        return () => {
            console.log('Unmounting useAnalytics')
        }

    }, [event])

    const sendEvent = (event) => {
        console.log('Sending event', event)
        fetch(`${process.env.REACT_APP_ANALYTICS}/events`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(event)
        })
        .then(response => response.json())
        .then(data => {
            setData(data)
            setPreviousEvent(event.canSend)
        })
        .catch(error => {
            setError(error)
        })
    }

    return { data, error, sendEvent }
}
