import menuIcon from '../../assets/img/ico-menu-n.png'

export default function NavButton() {
    
    const handleClick = () => {
        console.log("Menu click");
        document.body.classList.toggle('menu-active')
    }

    return (
        <button onClick={handleClick}>
            <img src={menuIcon} alt="" />
            <span>MENÚ</span>
        </button>
    )
}
// const [queryParams, setQueryParams] = useSearchParams()

// useEffect(() => {
//     if (queryParams.get('menu') === 'true') {
//         document.body.classList.add('menu-active')
//     } else {
//         document.body.classList.remove('menu-active')
//     }
// }, [queryParams])