import { Fragment } from "react"
import { motion } from "framer-motion"
export default function CarType({ type, clickType, setCurrentModels, layout, delay = 0, eventTracker }) {

    const handleClick = () => {
        const event = {
            type: 'pageview',
            referrer: '/modelos/'+type.nombre.toUpperCase(),
            session_token: localStorage.getItem('session'),
            totem: process.env.REACT_APP_TOTEM_NAME,
            canSend: true
        }
        eventTracker(event)
        clickType(type)
        setCurrentModels({ title: type.nombre.toUpperCase(), modelos: type.modelos })
    }

    const featuredImage = `${process.env.REACT_APP_UPLOAD_URL}${type.featured_image.data.attributes.url}`
    const backgroundImage = `${process.env.REACT_APP_UPLOAD_URL}${type.background_image.data ? type.background_image.data.attributes.url : type.featured_image.data.attributes.url}`

    const getLayout = () => {
        switch (layout.id) {
            case "cont-tipo":
                return (
                    <>
                        <img src={featuredImage} alt="" />
                        <span>{type.nombre.toUpperCase()}</span>
                    </>
                )

            case "cont-rango-precios":
                return (
                    <>
                        <div className="caja-1">
                            <div className="barra"></div>
                            <div className="cont-monto">
                                <span>{type.nombre.toUpperCase()}</span>
                            </div>
                        </div>
                        <div className="caja-2"></div>
                        <img src={featuredImage} alt="" />
                    </>
                )

            case "cont-uso":
                return (
                    <>
                        <span>{type.nombre.toUpperCase()}</span>
                        <img src={backgroundImage} alt="" />
                        <img className="dest" src={featuredImage} alt="" />
                    </>
                )

            case "cont-pasajeros":
                return (
                    <>
                        <span>{type.nombre.toUpperCase()}</span>
                        <img src={featuredImage} alt="" />
                    </>
                )

            case "cont-transmision":
                return (
                    <>
                        <img src={featuredImage} alt="" />
                    </>
                )


            default:
                return (
                    <>
                        <img src={`${process.env.REACT_APP_UPLOAD_URL}${type.featured_image.data.attributes.url}`} alt="" />
                        <span>{type.nombre.toUpperCase()}</span>
                    </>
                )
        }
    }

    return (
        <motion.button
            initial={{ opacity: 0, x: "200%" }}
            animate={{ opacity: 1, x: "0" }}
            transition={{
                duration: 0.1,
                delay: delay * 0.1,
                ease: 0
            }}
            onClick={handleClick} >
            {getLayout()}
        </motion.button>
    )


}
