import { Link } from "react-router-dom"
import { STRAPI_KEY } from '../../helpers/settings/Settings'
import { useRef, useState, useEffect } from "react"
import useOutsideAlerter from "../../hooks/useOutsideAlerter"
import navBanner from '../../assets/img/logo-cs-menu.png'
export default function SidebarNav() {

    const [filtros, setFiltros] = useState(null)
    const [beneficios, setBeneficios] = useState(null)
    const [otros, setOtros] = useState(null)

    const navRef = useRef(null)
    useOutsideAlerter(navRef)

    useEffect(() => {
        if (!filtros) {
            fetch(`${process.env.REACT_APP_API_URL}/tipos-de-vehiculos?populate=tipos.background_image,tipos.featured_image,tipos,tipos.modelos, tipos.modelos.featured_image`, { headers: { Authorization: `Bearer ${STRAPI_KEY}` } })
                .then(response => response.json())
                .then(data => setFiltros(data.data))
        }
    }, [filtros])


    useEffect(() => {
        if (!beneficios) {
            fetch(`${process.env.REACT_APP_API_URL}/totem-pages/1?populate=page_header_image,tabs,tabs.featured_image`, { headers: { Authorization: `Bearer ${STRAPI_KEY}` } })
                .then(response => response.json())
                .then(data => {
                    setBeneficios(data.data.attributes)
                })
        }
    }, [beneficios])


    useEffect(() => {
        if (!otros) {
            fetch(`${process.env.REACT_APP_API_URL}/totem-pages/2?populate=page_header_image,tabs,tabs.featured_image`, { headers: { Authorization: `Bearer ${STRAPI_KEY}` } })
                .then(response => response.json())
                .then(data => setOtros(data.data.attributes))
        }
    }, [otros])

    return (
        <div className="wrap-menu" >
            <div className="cont-menu" ref={navRef}>
                <img src={navBanner} alt="" />
                <div className="sop-menu">
                    <span className="tit-menu">
                        MENÚ
                    </span>
                    <nav className="nav-menu">
                        <div className="cont-nav">
                            <Link to={'/salfa'} onClick={() => document.body.classList.remove('menu-active')} className="tit-nav">Salfa</Link>
                        </div>
                        <div className="cont-nav">
                            <Link to={'/modelos'} onClick={() => document.body.classList.remove('menu-active')} className="tit-nav">Modelos</Link>
                            <ul>
                                {filtros && filtros.map((filtro, index) => <li key={'sidenav-link-' + index}><Link to={'/modelos?tab=' + filtro.id} onClick={() => document.body.classList.remove('menu-active')} >{filtro.attributes.button_label}</Link></li>)}
                            </ul>
                        </div>
                        <div className="cont-nav">
                            <Link to={'/beneficios'} onClick={() => document.body.classList.remove('menu-active')} className="tit-nav">Beneficios</Link>
                            <ul>
                                {beneficios &&
                                    beneficios.tabs.map((beneficio, index) => <li key={'sidenav-link-beneficio' + index}><Link to={'/beneficios?tab=' + beneficio.id} onClick={() => document.body.classList.remove('menu-active')} >{beneficio.cta_button_label}</Link></li>)}
                            </ul>
                        </div>
                        <div className="cont-nav">
                            <Link to={'/otros'} onClick={() => document.body.classList.remove('menu-active')} className="tit-nav">Otros Productos</Link>

                            <ul>
                                {otros &&
                                    otros.tabs.map((otro, index) => <li key={'sidenav-link-otro' + index}><Link to={'/otros?tab=' + otro.id} onClick={() => document.body.classList.remove('menu-active')} >{otro.title}</Link></li>)}
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}
