import React from 'react'

export default function Accesories({ data }) {

    const personaliza = data.data.attributes.personaliza
    return (
        <div className="accesorios">
            {personaliza &&
                <>
                    <p>{personaliza.titulo}</p>
                    <p>{personaliza.descripcion}</p>
                    { process.env.REACT_APP_UPLOAD_URL+personaliza.imagen_destacada && <img className="acc-img" src={process.env.REACT_APP_UPLOAD_URL+personaliza.imagen_destacada.data.attributes.url} alt="" /> }
                    { process.env.REACT_APP_UPLOAD_URL+personaliza.qr_catalogo && <img className="acc-qr" src={process.env.REACT_APP_UPLOAD_URL+personaliza.qr_catalogo.data.attributes.url}  alt="" /> }
                    {process.env.REACT_APP_UPLOAD_URL+personaliza.accesorios &&  <img className="acc-varios" src={process.env.REACT_APP_UPLOAD_URL+personaliza.accesorios.data.attributes.url}  alt="" /> }
                </>
            }
        </div>
    )
}
