import { STRAPI_KEY } from '../../helpers/settings/Settings'
import { useEffect, useState } from "react";
import BlogLayout from "../../layouts/BlogLayout";
import Badge from "./Badge";
import Footer from "./Footer";
import { useAnalytics } from '../../hooks/useAnalytics';
import { v4 as uuid } from 'uuid'

export default function SponsorPage() {

  const [data, setData] = useState(null)
  
  const { error, sendEvent } = useAnalytics({
    type: 'pageview',
    referrer: '/salfa',
    session_token: localStorage.getItem('session') || uuid(),
    totem: process.env.REACT_APP_TOTEM_NAME,
    canSend: true
  })

  useEffect(() => {
    if (!data) {
      fetch(`${process.env.REACT_APP_API_URL}/totem-pages/3?populate=page_header_image,badges.badge_image,footer,footer.imagen_banner,footer.imagen_qr`, { headers: { Authorization: `Bearer ${STRAPI_KEY}` } })
        .then(response => response.json())
        .then(data => setData(data.data.attributes))
    }
  }, [])

  return (
    <BlogLayout pageId="w-salfa">
      {data &&
        <div className="cont-interior">
          {data.page_header_image && <img src={process.env.REACT_APP_UPLOAD_URL + data.page_header_image.data.attributes.url} alt="" className="equipo-salfa" />}

          {data.badges &&
            < div className="cont-sellos-salfa">
              {data.badges.map((badge, index) => <Badge key={badge.id + "-badge"} image={badge.badge_image ? process.env.REACT_APP_UPLOAD_URL + badge.badge_image.data.attributes.url : false} text={badge.text} />)}
            </div>
          }

          {data.footer && <Footer text={data.footer.descripcion} banner={process.env.REACT_APP_UPLOAD_URL + data.footer.imagen_banner.data.attributes.url} qr={process.env.REACT_APP_UPLOAD_URL + data.footer.imagen_qr.data.attributes.url} />}
        </div>
      }
    </BlogLayout >
  )
}